.phone {
  position: relative;
  max-width: 375px;
  margin: 0 auto;
}

.phone h3,
.phone p {
  font-size: 17px;
  line-height: 22px;
  color: #fff;
}

.phone::before {
  content: '';
  position: fixed;
  box-sizing: border-box;
  top: 16px;
  left: calc(50% - 214px);
  display: block;
  height: 868px;
  width: 428px;
  z-index: 100;
  border-radius: 68px;
  border: 26.5px solid #222;
  pointer-events: none;
  box-shadow: 0 0 0 100vw #e54b4b;
}

.phone::after {
  content: '';
  position: fixed;
  box-sizing: border-box;
  top: 16px;
  left: calc(50% - 214px);
  display: block;
  height: 868px;
  width: 428px;
  z-index: 100;
  border-radius: 68px;
  pointer-events: none;
  animation: light 20s linear infinite;
}

@keyframes light {
  from {
    box-shadow: 0 0 100px 50px #e44a66;
  }

  33% {
    box-shadow: 0 0 100px 50px rgb(255, 208, 0, 0.2);
  }

  66% {
    box-shadow: 0 0 100px 50px rgb(44, 21, 157, 0.2);
  }

  to {
    box-shadow: 0 0 100px 50px #e44a66;
  }
}

@media only screen and (min-width: 500px) {
  .phone {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 500px) {
  .phone::before,
  .phone::after {
    content: none;
  }

  .phone {
    max-width: 100%;
  }
}

@media only screen and (max-height: 500px) {
  .phone::before,
  .phone::after {
    top: -80px;
  }

  .phone {
    padding-top: 0;
  }
}

@media only screen and (min-height: 894px) {
  .phone::before,
  .phone::after {
    top: calc(50vh - 434px);
  }

  .phone {
    padding-top: calc(50vh - 400px);
  }
}

.menuItemLink {
  color: #fff !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.menuItemLink:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 1) !important;
}

.headerMenu {
  width: 100%;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin: -32px 0 0;
}

.helloAnimatedHeading {
  will-change: transform;
  transform: translate3d(0, 40px, 0);
  opacity: 0;
  transition: all 0.6s ease-out;
}

.helloAnimatedDescription {
  composes: helloAnimatedHeading;
  transition-delay: 0.3s;
}

.loaded {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.cta {
  text-align: center;
  color: #fff;
  margin: 40px 0 60px;
}

.archery {
  fill: #e44a66;
  margin-bottom: -50px;
}

.cyclist {
  fill: #e44a66;
  margin-left: 160px;
  margin-bottom: -50px;
}

.mrchief {
  width: 280px;
  margin-bottom: 20px;
}

img.avatar {
  width: 48px;
  height: 48px;
  margin: 40px 0 4px;
}

input {
  display: inline-block;
  border: none !important;
  padding: 8px 16px 10px;
  font-size: 17px;
  line-height: 22px;
  width: 70%;
  border-radius: 17.5px 0 0 17.5px;
  margin: 0 0 0 4px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-out;
}

form {
  margin-top: 10px;
}

.birka {
  font-size: 17px;
  line-height: 22px;
  color: #fff;
  margin: 60px 0 10px;
}

.heading {
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 80px;
  line-height: 65px;
  letter-spacing: -0.02em;
  color: rgba(255, 255, 255, 0.05);
  -webkit-text-stroke: 2px #fff;
}

.spacer {
  min-height: calc(100vh - 868px);
}

@media only screen and (min-height: 894px) {
  .spacer {
    min-height: calc(50vh - 434px);
  }
}

.section {
  position: relative;
  padding: 40px 16px 120px;
  background-color: var(--background);
}

@media only screen and (min-width: 500px) {
  .section {
    margin: 0 -50px;
    padding: 40px 66px 120px;
  }
}

.section h3,
.section p,
.section li {
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  margin-bottom: 10px;
  white-space: normal;
  width: 100%;
}

.section h3 {
  margin-top: 20px;
}

.section ul {
  margin-bottom: 14px;
}

.section ul li {
  display: block;
  margin-bottom: 6px;
}

.section ul li:before,
.section ul li:first-child:before {
  content: '';
}

.contacts {
  padding-bottom: 20px;
  text-align: center;
}

h3 a {
  color: #fff !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.contacts a {
  color: #fff !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.section a {
  color: #2c159d;
  border-bottom: 1px solid rgba(44, 21, 157, 0.2);
}

h3 a:hover {
  border-color: rgba(255, 255, 255, 1) !important;
}

.contacts a:hover {
  border-color: rgba(255, 255, 255, 1) !important;
}

.section a:hover {
  color: #e44a66;
  border-color: rgba(228, 74, 102, 0.5);
}

.section::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -100px;
  border-width: 0 0 100px 100vw;
  border-color: transparent transparent var(--background) transparent;
  border-style: solid;
  overflow: hidden;
}

.section.first::before {
  border-color: var(--background);
}

@media only screen and (min-width: 500px) {
  .section::before {
    border-width: 0 0 100px 500px;
  }
}

.section.light h3,
.section.light p,
.section.light li {
  color: #000 !important;
}

.response {
  opacity: 1;
  transition: all 0.1s ease-out;
}

.is__fetching {
  opacity: 0;
}

.is__submitted {
  opacity: 1;
}

.icon {
  display: inline-block;
  vertical-align: middle;
}
