.button {
  display: block;
  color: #fff !important;
  border: none !important;
  background: #2c159d;
  padding: 8px 28px 11px;
  font-size: 22px;
  line-height: 28px;
  border-radius: 17.5px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  margin: 0 auto 4px;
  transition: all 0.2s ease-out;
}

.button:hover {
  color: #fff !important;
}

.buttonSmall {
  composes: button;
  display: inline-block;
  font-size: 17px;
  line-height: 22px;
  padding: 8px 28px 10px;
  min-width: 80px;
}

input + .buttonSmall {
  border-radius: 0 17.5px 17.5px 0;
}

.stickyHolder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  top: 2000px;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2000px;
}

.stickyHolder .button {
  position: sticky;
  bottom: 20px;
  will-change: transform;
  transform: translate3d(0, 40px, 0);
  opacity: 0;
  transition: all 0.6s ease-out;
  transition-delay: 0.3s;
  z-index: 99;
}

.stickyHolder.loaded .button {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

@media only screen and (min-height: 894px) {
  .stickyHolder .button {
    bottom: calc(50vh - 394px);
  }
}
