/* Дефолтные стили */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  text-size-adjust: none;
  outline: none;
}

/* Формы */
input,
textarea,
button {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
}

/* Аккуратные таблицы */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Основные стили */
html {
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 21px;
  box-sizing: border-box;
  margin: 0;
}

.webpage {
  max-width: 1380px;
  margin: 0 auto;
}

a {
  color: #125071;
  border-bottom: 1px solid rgba(12, 79, 114, 0.2);
  text-decoration: none;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  padding-top: 8px;
}

.menu {
  display: inline-block;
}

.logo-menu {
  display: inline-block;
}

.menu-item a.menu-button {
  text-align: center;
  color: #125071;
  border: 1px solid rgba(12, 79, 114, 0.2);
  text-decoration: none;
  padding: 3px 16px 5px;
  border-radius: 8px;
  white-space: nowrap;
  cursor: pointer;
}

.menu-item a.menu-button:hover {
  color: #fe5353;
  border-color: rgba(254, 83, 83, 0.5);
}

.menu-item {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 4px;
  font-size: 16px;
}

div.menu-item-last {
  margin-right: 0px;
}

.holder {
  padding-right: 9%;
  padding-left: 9%;
}

.header-menu {
  width: 100%;
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  margin-bottom: 40px;
}

#tg-icon {
  fill: #125071;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a:hover {
  color: #fe5353;
  border-color: rgba(254, 83, 83, 0.5);
}

a:hover #tg-icon {
  fill: #fe5353;
}

.tg-icon {
  display: inline-block;
  position: relative;
  top: 2px;
}

div.logo-img-holder {
  display: inline-block;
  margin-right: 6px;
  position: relative;
  width: 52px;
}

div.logo-img {
  position: absolute;
  top: -20px;
}

h1 {
  font-size: 50px;
  line-height: 55px;
  font-weight: 400;
}

.heading {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 160px;
}

h2 {
  font-size: 25px;
  line-height: 25px;
  margin: 20px 0 40px;
}

.shelf {
  background: linear-gradient(to bottom, rgb(51, 51, 51), rgb(25, 25, 25));
}

p {
  margin: 0 0 14px 0;
  width: 70%;
  max-width: 900px;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

abbr {
  font-size: 0.9em;
  line-height: 100%;
  letter-spacing: 0.15em;
  margin-right: -0.15em;
}

.rows {
  flex-flow: column;
}

.row {
  flex: 0 1 auto;
}

div.row div.cols {
  flex-flow: row;
  margin-right: -9px;
  margin-left: -9px;
}

.col {
  flex-basis: 0;
}

.col-in {
  padding-right: 9px;
  padding-left: 9px;
}

.module {
  display: flex;
  flex: 1;
  flex-flow: column;
  flex-shrink: 0;
}

.module .col-2 {
  flex-grow: 2;
}

.module .col-4 {
  flex-grow: 4;
}

.module .col-5 {
  flex-grow: 4;
}

.caterpillar {
  align-items: flex-end;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding-bottom: 20px;
}

.caterpillar-img {
  width: 300px;
  height: 100px;
  margin: 0px 0 10px;
}

.apps-holder {
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-shrink: 0;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}

.apps-grid {
  margin: 0 25px 160px;
  flex-basis: 250px;
  flex-shrink: 1;
}

.books-holder-first,
.books-holder {
  display: flex;
  flex: 1;
  flex-flow: row;
  flex-shrink: 0;
  justify-content: space-around;
  padding-top: 40px;
}

.books-holder-shelf {
  background: rgb(51, 51, 51);
  height: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: inset 0px -1px 0 0px rgba(255, 255, 255, 0.05);
}

.book {
  position: relative;
  width: 200px;
  height: 282px;
  background-size: 200px 282px;
}

.book img {
  position: absolute;
}

.book:hover {
  box-shadow: 0 0 50px 0 rgba(255, 243, 188, 0.4);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.main-book {
  position: relative;
  width: 400px;
  height: 564px;
}

.main-book img {
  position: absolute;
}

.main-book:hover {
  box-shadow: 0 0 50px 0 rgba(255, 243, 188, 0.4);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.main-book p {
  position: absolute;
  left: -240px;
  bottom: 5px;
  color: rgba(255, 255, 255, 0.8);
}

.main-book p:hover {
  color: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.main-book:hover > p {
  color: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a.book-link {
  border: none;
  color: rgba(255, 255, 255, 0.8);
}

a.book-link:hover {
  color: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

a.book-link:hover > p {
  color: rgba(255, 255, 255, 1);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.card {
  position: relative;
  border-radius: 10px;
  width: 100%;
  padding-bottom: 60%;
}

.card:hover {
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.4);
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

div.card.school {
  background-image: url(http://fff.works/assets/img/bg.jpg);
  background-size: cover;
}

.b-label {
  z-index: 1000;
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 10px;
  right: 10px;
}

.shelf-h2 {
  color: white;
}

.gorilla {
  position: absolute;
  width: 75%;
  right: -17%;
  top: -12%;
  z-index: 0;
}

.shark {
  position: absolute;
  width: 72%;
  bottom: 0;
  right: -43%;
}

.card-description {
  margin: 20px 0 80px;
  width: 40%;
}

.card-description-course {
  margin: 20px 0 80px;
}

.footer {
  padding-bottom: 30px;
  padding-top: 50px;
}

p.lead {
  font-size: 21px;
  line-height: 28px;
}

.mountain {
  position: absolute;
  bottom: -12%;
  right: 2%;
  width: 85%;
}

.managers {
  position: absolute;
  width: 50%;
  top: -3%;
  left: -1.5%;
}

.toverovskiy {
  position: absolute;
  width: 25%;
  bottom: -8%;
  right: 2%;
}

.kurs-deadline,
.kurs {
  position: absolute;
  font-weight: bold;
  top: 3%;
  left: 3%;
  color: white;
  line-height: 1.2;
  & span {
    line-height: 1.3;
    font-weight: normal;
  }
}

.kurs {
  font-size: 4vw;
}

.kurs span {
  font-size: 2vw;
}

.kurs-deadline {
  font-size: 2.8vw;
}

.kurs-deadline span {
  font-size: 1.4vw;
}

.h1-caption {
  font-size: 50px;
  line-height: 50px;
  margin: -6px 0 20px;
  font-weight: bold;
}

.cta-card {
  width: 50%;
}

.apps-holder + .apps-holder {
  margin-top: -60px;
}

.apps-holder:last-child {
  margin-bottom: 80px;
}

.book-soon {
  opacity: 0.2;
}

.book-soon:hover {
  box-shadow: none;
}

ul.links {
  margin: 20px 0 4px 0;
  list-style: none;
}
ul > li {
  display: inline;
  white-space: nowrap;
}
ul > li:before {
  content: ' ·  ';
}
ul > li:first-child:before {
  content: '';
}

ol {
  padding-inline-start: 1em;
}

/* hack to reduce extra space in safari ol */
@media not all and (min-resolution: 0.001dpcm) {
  @media {
    ol {
      padding-inline-start: 1.5em;
    }
  }
}

.book-soon p {
  color: white;
  position: absolute;
  width: 200px;
  text-align: center;
  top: -23px;
}

a.button-cta {
  width: 100%;
  max-width: 400px;
  display: block;
  height: 70px;
  background: #39f9bf;
  border: none;
  border-radius: 10px;
  padding: 22px;
  text-align: center;
  font-size: 25px;
  color: black;
}

a.tablet,
div.tablet {
  display: none;
}
a.no-tablet,
div.no-tablet {
  display: inherit;
}

a.mobile,
div.mobile {
  display: none;
}
a.no-mobile,
div.no-mobile {
  display: inherit;
}

@media (max-width: 1023px) {
  .holder {
    padding-right: 4%;
    padding-left: 4%;
  }

  .main-book {
    width: 300px;
    height: 423px;
  }

  a.tablet {
    display: inherit;
  }

  div.tablet {
    display: flex;
  }

  img.no-tablet,
  p.no-tablet,
  a.no-tablet,
  div.no-tablet {
    display: none;
  }

  a.mobile,
  div.mobile {
    display: none;
  }
}

@media (max-width: 500px) {
  .main-book {
    width: 200px;
    height: 282px;
  }

  p,
  p.card-description {
    width: 100%;
  }

  .heading {
    padding-bottom: 120px;
  }

  h1,
  .h1-caption {
    font-size: 30px;
    line-height: 35px;
  }

  a.tablet,
  div.tablet {
    display: none;
  }
  img.no-tablet,
  a.no-tablet,
  div.no-tablet {
    display: inherit;
  }

  a.mobile {
    display: inherit;
  }

  div.mobile {
    display: flex;
  }

  img.no-mobile,
  p.no-mobile,
  span.no-mobile,
  a.no-mobile,
  div.no-mobile {
    display: none;
  }
}

@media (max-width: 800px) {
  .card-description {
    width: 50%;
  }

  .cta-card {
    width: 100%;
  }
}

@media (max-width: 520px) {
  span.menu-hidden {
    display: none;
  }
}

#stickyButtonStart {
  padding-bottom: 40px;
}
