.holder {
  position: relative;
  transform: translate3d(0, 0, 0);
  will-change: transform, opacity;
  transition: all 3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  opacity: 1;
}

.rocket {
  position: absolute;
  bottom: -120px;
  left: 0;
  fill: #2c159d;
}

.fire {
  position: absolute;
  bottom: -160px;
  left: 17px;
  fill: #ffd000;
}

.visible {
  transform: translate3d(0, -800px, 0);
  opacity: 0;
}
