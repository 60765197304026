.scooter {
  fill: #2C159D;
  position: absolute;
  bottom: -40px;
  left: 10px;
  z-index: 1;
  will-change: transform;
  transform: translate3d(0,0,0);
  transition: transform 2s ease-out;
}

.scooter.visible {
  transform: translate3d(300px,-80px,0);
}
