.module {
  display: flex;
  flex: 1;
  flex-flow: column;
  flex-shrink: 0;
}

.module .col-2 {
  flex-grow: 2;
}

.module .col-4 {
  flex-grow: 4;
}

.module .col-5 {
  flex-grow: 5;
}

.row {
  flex: 0 1 auto;
}

.row div.cols {
  flex-flow: row;
  margin-right: -9px;
  margin-left: -9px;
}
