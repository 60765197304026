.holder {
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  z-index: 99;
}

.balloon {
  position: relative;
  display: inline-block;
  max-width: 240px;
  padding: 8px 12px;
  background: #fff;
  font-size: 17px;
  line-height: 22px;
  border-radius: 17.5px;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.2);
  opacity: 1;
  transition: all 0.2s ease-out;
  transform: translateZ(0);
}

.balloon::after {
  content: url(./static/leg.svg);
  position: absolute;
  left: -4px;
  bottom: 0;
  width: 14px;
  height: 18px;
}

.balloon + .balloon {
  margin-top: 10px;
}

.balloon.hidden {
  opacity: 0;
  transform: translate3d(0, 20px, 0);
}

.balloon.isRight {
  align-self: flex-end;
}

.balloon.isRight::after {
  left: auto;
  right: -4px;
  transform: scaleX(-1);
}
