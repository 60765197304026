.grid {
  margin: 0 25px 160px;
  flex-basis: 250px;
  flex-shrink: 1;
}

.link {
  border: none;
  color: black;
  display: block;
  width: 100%;
}

.link:hover {
  color: #fe5353;
}

.app {
  flex-basis: 186px;
  flex-shrink: 1;
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  background-size: 186px 186px;
}

.app:last-child {
  margin-right: 0;
}

.game {
  overflow: visible;
  flex-shrink: 1;
  width: 86%;
  padding-bottom: 86%;
  margin: 0 auto;
  position: relative;
  background-image: url(http://fff.works/assets/img/white-box.png);
  background-size: cover;
}

.game > .description {
  top: 116%;
}

.description {
  width: 100%;
  position: absolute;
  top: 100%;
}

.description p {
  margin: 0 auto;
  text-align: center;
}

.gameImage {
  position: absolute;
  width: 100%;
  height: 138%;
  background-size: cover;
  top: -20%;
}

.gun {
  composes: gameImage;
}

.cat {
  composes: gameImage;
  width: 155%;
  height: 140%;
  top: -26%;
  left: -19%;
}

.appImage {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  top: -3px;
}

.octopus {
  composes: appImage;
}

.chief {
  composes: appImage;
}

.conspectus {
  composes: appImage;
  width: 136%;
  height: 136%;
  top: -21.5%;
  left: -18%;
}
