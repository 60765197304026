.wrapper {
  position: relative;
  transform: translateZ(0);
}

.animationHolder {
  position: absolute;
  top: 0;
  right: 140px;
  width: 140px;
  height: 140px;
  animation: round 3s infinite linear;
  transform-origin: 50% 50%;
}

.face {
  margin-top: 40px;
  will-change: fill;
  fill: #e44a66;
}

.sign {
  position: absolute;
}

.animated {
  animation: anti-round 3s infinite linear;
}

.shark,
.worker,
.flame {
  fill: #e44a66;
}

.magic,
.like,
.laptop {
  fill: #2c159d;
  composes: animated;
}

.shark {
  top: 20px;
  left: 0;
  animation: fade-one 10s linear infinite;
}

.worker {
  top: 120px;
  left: 20px;
  animation: fade-two 10s linear infinite;
}

.flame {
  top: 40px;
  left: 140px;
  animation: fade-three 10s linear infinite;
}

.magic {
  top: 0;
  left: 10px;
}

.like {
  top: 100px;
  left: 0px;
}

.laptop {
  top: 60px;
  left: 100px;
}

.ufo {
  top: 0;
  left: 10px;
}

.gear {
  top: 100px;
  left: 10px;
}

.knife {
  top: 40px;
  left: 80px;
}

@keyframes round {
  to {
    transform: rotate(1turn);
  }
}

@keyframes anti-round {
  to {
    transform: rotate(-1turn);
  }
}
